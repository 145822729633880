/**
 * Note: An intermediate solution to provide the links for footer navigation.
 * @todo Get the data from Aggregator backend
 */

export const footerNavigation = [
  {
    name: "Imprint",
    href: "/imprint",
  },
  {
    name: "Contact",
    href: "/contact",
  },
];
