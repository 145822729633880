import React from "react";
import config from "../../lib/config";
import { footerNavigation as navigation } from "../../lib/data/footer-navigation";
import Image from "next/image";
import { Nav } from "@precomposer/ui";
import TwitterSvg from "../../assets/images/twitter.svg";
import FacebookSvg from "../../assets/images/facebook.svg";

const Footer = () => {
  return (
    <footer
      className={"grid grid-cols-3 justify-items-stretch py-3 lg:border-t"}
    >
      <div>
        <Nav direction={"vertical"}>
          {navigation.map((item, index) => {
            return (
              <Nav.Item key={index}>
                <Nav.Link href={item.href}>{item.name}</Nav.Link>
              </Nav.Item>
            );
          })}
        </Nav>
      </div>
      <p className={"flex items-center justify-self-center"}>
        &copy; Copyright by {config.companyName}
      </p>
      <div className={"flex justify-self-end"}>
        <Image
          src={FacebookSvg}
          alt={"Find us on facebook"}
          className={"h-8 w-8"}
        />
        <Image
          src={TwitterSvg}
          alt={"Fin us in twitter"}
          className={"h-8 w-8"}
        />
      </div>
    </footer>
  );
};

export default Footer;
