import React, { HTMLAttributes, ReactNode } from "react";
import classNames from "classnames";

type Props = {
  children?: ReactNode;
} & HTMLAttributes<HTMLElement>;

const Header = ({ children, className }: Props) => {
  return (
    <header className={classNames(className, "pb-3 md:mb-3 md:border-b")}>
      {children}
    </header>
  );
};

export default Header;
