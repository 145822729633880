import React, { FC } from "react";
import { Heading } from "@precomposer/ui";

type Props = {
  text: string;
  title: string;
};

const Article: FC<Props> = ({ text, title }) => {
  return (
    <article className={"mt-6"}>
      <header>
        <Heading element={"h2"} className={"pb-3"}>
          {title}
        </Heading>
      </header>
      <p>{text}</p>
    </article>
  );
};

export default Article;
