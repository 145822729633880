import React, { FC, ReactElement, useState } from "react";
import { useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import Header from "./Header";
import Footer from "./Footer";
import Logo from "./Logo";
import { Heading, Navbar, OffCanvas } from "@precomposer/ui";
import SearchForm from "../search/SearchForm";
import CartCounter from "../cart/CartCounter";
import AccountMenu from "../account/AccountMenu";
import AccountSignInForm from "../account/AccountSignInForm";
import renderer from "../cms/Components";
import { ShoppingCartIcon, UserIcon } from "@heroicons/react/24/outline";
import NotificationBanner from "../cms/NotificationBanner";
import { AppRoutes } from "../../lib/data/app-routes";
import { env } from "@precomposer/env";

type Props = {
  children: ReactElement;
};

const DefaultLayout: FC<Props> = ({ children }) => {
  const { t } = useTranslation(["common", "account"]);
  const [show, setShow] = useState(false);
  const { data: session, status } = useSession();
  const NOTIFICATION_BANNER_MESSAGE = env("NOTIFICATION_BANNER_MESSAGE");
  const IS_PERMANENT = env("NOTIFICATION_BANNER_IS_PERMANENT") === "true";

  const toggleShow = () => {
    setShow(!show);
  };

  return (
    <div className="flex h-screen flex-col justify-between lg:container lg:mx-auto">
      <Header>
        <div>
          <div>
            <NotificationBanner
              isPermanent={IS_PERMANENT}
              bgColor={"notification-bar-color"}
            >
              {NOTIFICATION_BANNER_MESSAGE}
            </NotificationBanner>
          </div>
        </div>
        <Navbar>
          <Navbar.Toggle />
          <Navbar.Brand href={"/"} className={"md:order-1"}>
            <Logo />
          </Navbar.Brand>
          <div className={"flex md:order-3 md:px-3"}>
            <button
              onClick={toggleShow}
              className={"py-2 pr-3"}
              role={"button"}
            >
              <UserIcon className={"block h-6 w-6"} />
              <span className={"sr-only"}>{t("common:navbar.account")}</span>
            </button>
            <Link href={AppRoutes.CART} className={"relative mr-4 py-2"}>
              <ShoppingCartIcon className={"block h-6 w-6"} />
              <span className={"sr-only"}>{t("common:navbar.cart")}</span>
              <CartCounter />
            </Link>
          </div>
          <SearchForm className={"md:order-2 md:flex-1"} />
          {renderer(children.props?.navigationContent)}
        </Navbar>
      </Header>
      <main className={"flex-1 px-3 lg:px-0"}>{children}</main>
      <Footer />
      <OffCanvas show={show} onClose={toggleShow}>
        <OffCanvas.Header>
          <Heading element={"h5"}>{t("common:navbar.account")}</Heading>
        </OffCanvas.Header>
        <OffCanvas.Body>
          {session ? (
            <AccountMenu />
          ) : (
            <AccountSignInForm className={"grid gap-6"} />
          )}
        </OffCanvas.Body>
      </OffCanvas>
    </div>
  );
};

export default DefaultLayout;
