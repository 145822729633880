import React from "react";
import { signOut, useSession } from "next-auth/react";
import { useTranslation } from "next-i18next";
import Link from "next/link";
import { Button } from "@precomposer/ui";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/outline";

const AccountMenu = () => {
  const { t } = useTranslation(["account"]);
  const { data: session } = useSession();

  return (
    <>
      <strong className={"mb-6 block"}>
        {t("account:menu.welcome", { name: session?.user?.name })}
      </strong>
      <ul>
        <li className={"my-2"}>
          <Link href={"/account"}>{t("account:menu.dashboard")}</Link>
        </li>
        <li className={"my-2"}>
          <Link href={"/account/address"}>{t("account:menu.addresses")}</Link>
        </li>
        <li className={"mt-6"}>
          <Button
            onClick={() => {
              signOut();
            }}
            variant={"primary"}
            size={"large"}
            className={"block w-full"}
          >
            <ArrowRightOnRectangleIcon
              className={"mr-2 inline-block h-5 w-5"}
            />
            {t("account:menu.sign-out")}
          </Button>
        </li>
      </ul>
    </>
  );
};

export default AccountMenu;
